"use client";

import { ReactNode, useEffect, useState } from "react";
import SideNav from "./side-nav";
import { usePathname } from "next/navigation";
import NavLayoutFluidWrapper from "@/components/nav/nav-layout-fluid-wrapper";
import MobileNav from "@/components/nav/mobile-nav";
import MobileNavButton from "@/components/nav/mobile-nav-button";
import GlobalObserver from "@/components/global-observer";
import axios from "axios";
interface Props {
  children: ReactNode;
}

export function NavLayout(props: Props) {
  // Mobile Safari ignores overflow:hidden if the position of the containing element is not explicitly set (to relative)...
  // On top of that, browser detection through detect() doesn't work on mobile Safari. We gotta resort to the approach
  // below, which doesn't seem to break anything. Please don't touch this. Very fragile.
  // Don't ask me why. I hate it. Truly.
  const [init, setInit] = useState(false);

  useEffect(() => {
    if (init) return;
    // send API request
    axios.post("/mvnxwebsite/api/pageInit").then((res) => {
      setInit(true);
      console.log(res);
    });
  }, []);
  const pathname = usePathname();
  const additionalClasses =
    pathname === "/about" ? "relative overflow-hidden touch-none" : "";
  return (
    <body
      suppressHydrationWarning={true}
      className={`flex flex-col lg:flex-row p-0 gap-0 m-0 h-dvh max-h-dvh justify-between ${additionalClasses}`}
    >
      <GlobalObserver />
      <MobileNav />
      <MobileNavButton />
      <NavLayoutFluidWrapper>{props.children}</NavLayoutFluidWrapper>
      <SideNav />
    </body>
  );
}
